const rules = {
    internal_id: [
        {
            required: true,
            message: "Číslo zaměstnance je povinné",
            trigger: "change",
        },
    ],
    name: [
        {
            required: true,
            message: "Jméno je povinné",
            trigger: "change",
        },
    ],
    surname: [
        {
            required: true,
            message: "Příjmení je povinné",
            trigger: "change",
        },
    ],
    birthday: [
        {
            required: true,
            message: "Datum narození je povinné",
            trigger: "change",
        },
    ],
    gender_id: [
        {
            required: true,
            message: "Pohlaví je povinné",
            trigger: "change",
        },
    ],
    personal_identification_number: [
        {
            required: true,
            message: "Rodné číslo je povinné",
            trigger: "change",
        },
    ],
    birth_place: [
        {
            required: true,
            message: "Místo narození je povinné",
            trigger: "change",
        },
    ],
    nationality_id: [
        {
            required: true,
            message: "Státní občanství je povinné",
            trigger: "change",
        },
    ],
    id_location: [
        {
            required: true,
            message: "Místo vydání dokladu je povinné",
            trigger: "change",
        },
    ],
    id_location_state: [
        {
            required: true,
            message: "Stát vydání dokladu je povinné",
            trigger: "change",
        },
    ],
    id_number: [
        {
            required: true,
            message: "Číslo dokladu je povinné",
            trigger: "change",
        },
    ],
    id_date: [
        {
            required: true,
            message: "Datum platnosti dokladu je povinné",
            trigger: "change",
        },
    ],
    relationship_status_id: [
        {
            required: true,
            message: "Rodinný stav je povinné",
            trigger: "change",
        },
    ]
};
const data = {
    id: "",
    internal_id: "",
    name: "",
    surname: "",
    birthday: "",
    gender_id: "",
    personal_identification_number: "",
    birth_place: "",
    nationality_id: "",
    id_location: "",
    id_location_state: "",
    id_number: "",
    id_date: "",
    born_surname: "",
    child_count: "",
    relationship_status_id: "",
    insurance_number: "",
    insurance_company: "",
    registration_number: "",
    first_job_in_cz: "",
    terminated_date: "",
    medical_checkup: "",

}

export interface IEmployee {
    id: number,
    internal_id: number,
    name: string,
    surname: string,
    birthday: string,
    gender_id: number,
    personal_identification_number: string,
    birth_place: string,
    nationality_id: number,
    id_location: string,
    id_location_state: string,
    id_number: string,
    id_date: string,
    born_surname: string,
    child_count: number,
    relationship_status_id: number,
    phone: number,
    email: string,
    insurance_number: string,
    insurance_company: string,
    registration_number: string,
    first_job_in_cz: boolean,
    terminated_date: string,
    medical_checkup: string,
}

export const definition = {
    id: 'number',
    internal_id: 'number',
    name: 'string',
    surname: 'string',
    birthday: 'string',
    gender_id: 'number',
    personal_identification_number: 'string',
    birth_place: 'string',
    nationality_id: 'number',
    id_location: 'string',
    id_location_state: 'string',
    id_number: 'string',
    id_date: 'string',
    born_surname: 'string',
    permanent_address: 'string',
    temp_address: 'string',
    email: 'string',
    phone: 'string',
    child_count: 'number',
    relationship_status_id: 'number',
    insurance_number: 'string',
    insurance_company: 'string',
    registration_number: 'string',
    first_job_in_cz: 'boolean',
    terminated_date: 'string',
    medical_checkup: 'string',
};

export {rules, data}
