import ApiService from "@/core/services/ApiService";

const enums = {
    educationLevel: 'education_level',
    employmentType: 'employment_type',
    gender: 'gender',
    nationality: 'nationality',
    relationshipStatus: 'relationship_status',
    wageType: 'wage_type',
    workShift: 'work_shift',
}
const enumMock = {
    gender: [
        {
            id: 1,
            title: "Muž"
        },
        {
            id: 2,
            title: "Žena"
        }
    ],
    nationality: [
        {id: 1, title: "BG"},
        {id: 2, title: "CZ"},
        {id: 3, title: "KZ"},
        {id: 4, title: "PL"},
        {id: 5, title: "RO"},
        {id: 6, title: "SK"},
        {id: 7, title: "UA"}
    ],
    relationshipStatus: [
        {id: 1, title: "svobodný"},
        {id: 2, title: "ženatý"},
        {id: 3, title: "rozvedený"},
        {id: 4, title: "vdovec"}
    ],
    workShift: [
        {id: 1, title: "jednosměnný"},
        {id: 2, title: "dvousměnný"},
        {id: 3, title: "třísměnný"},
        {id: 4, title: "čtyřsměnný"},
    ],
    employmentType: [
        {id: 1, title: "HPP"},
        {id: 2, title: "DPČ"},
        {id: 3, title: "DPP"},
    ],
    wageType: [
        {id: 1, title: "hodinová"},
        {id: 2, title: "měsíční"},
    ]
}
export  function getMockEnum(name: string) {
    return enumMock[name];
}

export  function getEnum(name: string)  {
    let url = createEnumUrl(name);
    if (url !== null) {
        return ApiService.get(url);
    }
    throw new Error(`Enum not found`);

}

function createEnumUrl(name: string): string | null {
    if (name in enums !== undefined) {
        return "enum/" + enums[name] + "/all"
    } else {
        console.error('Enum doesnt exist in definition! ')
    }
    return null;

}
