
import {defineComponent, ref} from "vue";
import {castData} from "@/core/data/data_prepare";
import {Api} from "@/store/enums/StoreEnums";
import {data as empData, rules as empRules, definition as empDefinition} from "@/model/employee";
import router from "@/router";
import {getEnum} from "@/core/helpers/enums";
import ApiService from "@/core/services/ApiService";
import apiService from "@/core/services/ApiService";


export default defineComponent({
  name: "EditEmployeeForm",
  data() {
    return {
      employeeId: this.$route.params.id_emp,
      gender_enum: [],
      nationality_enum: [],
      relationship_enum: [],
      formData: empData,
      rules: ref(empRules),
      loading: false,
      last_internal_id: 0,
      last_id: 0,

    }
  },
  created() {
    getEnum('gender').then(({data}) => {
      this.gender_enum = data;
    });
    getEnum('nationality').then(({data}) => {
      console.log(data);
      this.nationality_enum = data;
    });
    getEnum('relationshipStatus').then(({data}) => {
      this.relationship_enum = data;
    });
    ApiService.get(Api.GET_EMPLOYEE, {employee_id: this.employeeId}).then(response => {
      this.formData = response.data
    });

    apiService.get(Api.GET_EMPLOYEE_LAST_ID).then(({data}) => {
      console.log(data);
      if (data['last_employee_id'] !== data['last_employee_internal_id']) {
        this.last_internal_id = data['last_employee_internal_id'];
      }
      this.last_id = data['last_employee_id'];
    });
    let secondaryBtn: HTMLElement = document.querySelector("[data-kt-toggle-name='aside-minimize']") as HTMLElement;
    if (secondaryBtn && !secondaryBtn.classList.contains('active')) {
      secondaryBtn.click()
    }

  },
  methods: {
    submit() {
      let formRef
      if (this.$refs.formRef) {
        formRef = ref(this.$refs.formRef);
      } else return;
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          this.loading = true;
          let data = castData(this.formData, empDefinition);
          console.log(data);
          ApiService.postFrom(Api.EDIT_EMPLOYEE, data, {'employee_id': this.employeeId})
            .then(response => {
              router.push({
                name: "employeeInfo",
                params: {id_emp: response.data.id}
              });
            })
            .catch(({response}) => {
              console.error(response);
              if (!isNaN(response)) {
              }
            });
        }
      });
    }

  }

});
